import React from 'react';

import { Helmet } from 'react-helmet';

import { extractBody, extractStyles } from '../utils/mailChimp';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container, Row, Col } from 'reactstrap';
import Link from '../net/Link';
import SubscriptionForm from '../components/SubscriptionForm';
import LoadingEff from '../components/LoadingEff';

import { graphql } from 'gatsby';

import moment from 'moment';

/**
 * Displays a mail campaign entry.
 */
import MailCampaignTemplateNavigation from '../components/MailCampaignTemplateNavigation';

class MailCampaignTemplate extends React.Component {

  render() {

    let { path, data } = this.props;
    const { markdownRemark } = data;
    const { html, frontmatter } = markdownRemark; 

    let title = frontmatter.title;
    let abstract = frontmatter.abstract;
    let neighbours = undefined;
    let date = frontmatter.date;
    let image = frontmatter.featureImage;

    let styles = extractStyles(this.props.pageContext.content);
    let content = extractBody(this.props.pageContext.content);

    let loading = false;
    let nextLink = undefined;
    let prevLink = undefined;
    let prevTitle = undefined;
    let nextTitle = undefined;

    if (neighbours) {
      if (neighbours.right) {
        prevLink = neighbours.right.node.frontmatter.path;
        prevTitle = neighbours.right.node.frontmatter.title;
      }
      if (neighbours.left) {
        nextLink = neighbours.left.node.frontmatter.path;
        nextTitle = neighbours.left.node.frontmatter.title;

      }
    }
    /*
    let now = moment();
    let render = now.isSameOrAfter(moment(date));
    console.log(title);
    console.log(moment(date));
    console.log(moment(date).format());
    console.log(render);
    if (render === false) {
      console.log('Setting article to hidden.');
      title = 'There is nothing here.';
      abstract = 'Please move along.';
    } else {
      console.log('Item is published.');
    }
    console.log(title);
    */

    /*
    Commented out the functionality of not rendering items to be published in the future
    because of very weird behaviour:
    If the site is built with the publish date set into the future, the content will never render
    if the page is accessed directly. render will be set to true, but the content will still not be
    displayed.
    BUT this changes if the content is accessed through the index page (click on teaser box, which is rendered):
    Then, render is true and the content actually does get rendered.

    I have no idea what is going on, and am lacking the energy to find out, so we just remove the 
    functionality alltogether.

    It's only relevant for /latest anyway.
    */

   let render = true;

    return (

      <Layout>
        <SEO
          title={title}
          description={abstract}
          image={image}
        />
        <Helmet
          style={[{
            "cssText": styles
          }]} />

        <Container fluid className="w-80 newsletter-campaign-view">
          <Row>
            <Col>
              <Link to="/lets-be-fwends/" className="archivelink">To the List Archive</Link>
            </Col>
          </Row>

          <Row>
            <Col><h1 className="newsletter-campaign" dangerouslySetInnerHTML={{ __html: title }}></h1></Col>
          </Row>

          <Row>
            <Col>
              <div className="newsletter-intro">
                <p className="lead">Get a bunch of links into your inbox every other week.</p>
                <p>Let's be Fwends is a journal about agility, organisations, technology, and the larger media landscape. And most importantly the role of all of us in all of that.</p>                
              </div>
            </Col>
            <SubscriptionForm />
          </Row>
          <MailCampaignTemplateNavigation path={this.props.path}/>
          
          <Row>

            <Col className="newsletter-campaign-view-body">
              <div className="campaign-wrapper">
                {loading ? <LoadingEff inline={true} /> : null}
                {render ? 
                <div dangerouslySetInnerHTML={{ __html: content }} />
                  : <div><p>Nothing to see here. Sorry.</p></div>
              }
              </div>
            </Col>

          </Row>

          <Row>
            <Col>
              <SubscriptionForm />
            </Col>
          </Row>


          <MailCampaignTemplateNavigation path={this.props.path}/>
          

        </Container>
      </Layout>


    )
  }

}

export default MailCampaignTemplate;


export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
        abstract
        featureImage
      }
    }
  }
  `
