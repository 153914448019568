import parse5 from 'parse5';

function getHtml(htmlDoc) {
  const doc = parse5.parse(htmlDoc);

  let html = undefined;

  //structure: #document - html - head // extract the style
  //also, we can extract the meta info from there.
  for (let i=0; i < doc.childNodes.length; i++) {
    if (doc.childNodes[i].nodeName === 'html') {
      html = doc.childNodes[i];
    }
  }
  return html;
}

export function extractStyles(doc) {
  let html = getHtml(doc);
  let head;
  let styles = '';
  for (let i=0; i < html.childNodes.length; i++) {
    if (html.childNodes[i].nodeName === 'head') {
      head = html.childNodes[i];
    }
  }

  if (head) {
    for (let i=0; i < head.childNodes.length; i++) {
      if (head.childNodes[i].nodeName === 'style') {
        styles += parse5.serialize(head.childNodes[i]);
      }
    }
  }

  return styles;
}


export function extractBody(doc) {
  let html = getHtml(doc);
  let body;

  for (let i=0; i < html.childNodes.length; i++) {
    if (html.childNodes[i].nodeName === 'body') {
      body = html.childNodes[i];
    }
  }

  if (body) {
    for (let i=0; i < body.childNodes.length; i++) {
      if (body.childNodes[i].nodeName === 'script') {
        body.childNodes.splice(i,1);
      }
    }
  }
  return parse5.serialize(body);
}